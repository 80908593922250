import React, { useEffect, useContext, createContext } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthErrorModal from '../components/Auth/AuthErrorModal'
import { useRecoilValue, useRecoilState } from 'recoil'
import { userProfileState } from './auth/userProfileState'
import { authSpinnerState } from './auth/authSpinnerState'
import { authErrorModalState } from './auth/authErrorModalState'
import { useProviderRedirect } from '../lib/auth/useProviderRedirect'

import { _getUserProfile } from '../lib/auth/getUserProfile'
import Loader from '../components/Common/Loader'

export const AuthContext = createContext(undefined)

export function AuthProvider({ children }: any) {
    const [userProfile, setUserProfile] = useRecoilState(userProfileState)
    const authSpinner = useRecoilValue(authSpinnerState)
    const [authErrorModal, setAuthErrorModal] = useRecoilState(authErrorModalState)

    const providerRedirect = useProviderRedirect().providerRedirect

    useEffect(() => {
        providerRedirect()
    }, [])

    useEffect(() => {
        const getUserProfile = async () => {
            const _userProfile = await _getUserProfile()
            if (_userProfile) {
                setUserProfile(_userProfile)
            }
        }

        if (Object.keys(userProfile).length === 0) {
            getUserProfile()
        }
    }, [userProfile])

    return (
        <>
            <ToastContainer
                className="toaster-container"
                position="bottom-center"
                autoClose={111111100}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {authSpinner && <Loader isLoading={authSpinner} />}

            <AuthErrorModal visible={authErrorModal} toggle={setAuthErrorModal} />

            {children}
        </>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
