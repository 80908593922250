import { firebaseClient } from '../../../firebaseClient'
import { FirebaseUser } from '../../../types/Auth/FirebaseUser'

export const getUser = async (uid: string): Promise<FirebaseUser> => {
    try {
        const userRef = await firebaseClient.firestore().collection('users').doc(uid).get()

        if (!userRef.exists) {
            // TODO: create a user if the user does not exists
            return null
        }

        const userRefData = (await userRef.data()) as FirebaseUser
        return userRefData
    } catch (error) {
        throw new Error(error.message)
    }
}
