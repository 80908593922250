import router from 'next/router'
import { useSetRecoilState } from 'recoil'
import { firebaseClient } from '../../firebaseClient'

export async function postUserToken(
    token: string,
    setAuthSpinner: (state: boolean) => void
): Promise<void | null> {
    const data: { token: string } = { token: token }
    const response = await fetch('/api/auth/session', {
        method: 'POST',
        body: JSON.stringify(data),
    })

    if (!response.ok) {
        setAuthSpinner(false)
        await firebaseClient.auth().signOut()
        await fetch('/api/auth/clearCookie', { method: 'POST' })
        router.push('/')
    }
}
