export const _getUserProfile = async () => {
    try {
        const response = await fetch('/api/auth/user')
        if (response.ok) {
            const data = await response.json()
            return data
        }
        return null
    } catch (error) {
        throw new Error(error.message)
    }
}
