import React from 'react'
import Modal from '../../components/Modal'

interface Props {
    toggle: (value: boolean) => void
    visible: boolean
}

const AuthErrorModal = ({ visible, toggle }: Props) => {
    return (
        <Modal
            classes="px-4 pt-6 pb-10 text-black flex flex-col items-center bg-gray-200 w-1/2"
            modalOpen={visible}
            toggleModal={toggle}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="80px"
                width="80px"
                viewBox="0 0 512 512"
            >
                <path
                    d="M322.939 62.642l178.737 309.583A76.877 76.877 0 01512 410.791c0 42.67-34.592 77.264-77.264 77.264H256L194.189 256 256 23.946c28.62 0 53.587 15.573 66.939 38.696z"
                    fill="#3b4145"
                />
                <path
                    d="M189.061 62.642L10.323 372.225A76.886 76.886 0 000 410.791c0 42.67 34.592 77.264 77.264 77.264H256V23.946c-28.62 0-53.587 15.573-66.939 38.696z"
                    fill="#525a61"
                />
                <path
                    d="M474.913 387.678L296.177 78.098c-8.056-13.959-22.849-22.767-38.848-23.22l152.869 402.275h24.539c25.559 0 46.358-20.798 46.358-46.358a46.346 46.346 0 00-6.182-23.117z"
                    fill="#ffb751"
                />
                <path
                    d="M444.853 387.678c3.492 7.005 5.336 14.999 5.336 23.117 0 25.559-17.935 46.358-39.992 46.358H77.264c-25.559 0-46.358-20.799-46.358-46.358a46.355 46.355 0 016.181-23.117l178.736-309.58c8.283-14.34 23.674-23.251 40.177-23.251.443 0 .886.01 1.329.031 13.732.536 26.414 9.323 33.326 23.22l154.198 309.58z"
                    fill="#ffd764"
                />
                <path
                    d="M256 354.131v51.509c14.227 0 25.755-11.528 25.755-25.755 0-14.226-11.528-25.754-25.755-25.754z"
                    fill="#3b4145"
                />
                <path
                    d="M256 354.131c2.843 0 5.151 11.528 5.151 25.755 0 14.227-2.308 25.755-5.151 25.755-14.227 0-25.755-11.528-25.755-25.755 0-14.227 11.528-25.755 25.755-25.755z"
                    fill="#525a61"
                />
                <path
                    d="M256 132.646V323.23c14.227 0 25.755-11.538 25.755-25.755V158.401c0-14.227-11.528-25.755-25.755-25.755z"
                    fill="#3b4145"
                />
                <path
                    d="M256 132.646c2.843 0 5.151 11.528 5.151 25.755v139.074c0 14.216-2.308 25.755-5.151 25.755-14.227 0-25.755-11.538-25.755-25.755V158.401c0-14.227 11.528-25.755 25.755-25.755z"
                    fill="#525a61"
                />
            </svg>
            <p className="text-center mt-6 mb-2">
                You are currently limited to 1 active campaign on a free plan.{' '}
            </p>
            <p className="my-2">
                To upgrade your account please contact{' '}
                <a href="mailto:sam@deployable.co" className="text-primary underline">
                    sam@deployable.co
                </a>{' '}
            </p>
        </Modal>
    )
}

export default AuthErrorModal
