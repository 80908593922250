import router from 'next/router'
import { useSetRecoilState } from 'recoil'
import { firebaseClient } from '../../firebaseClient'
import { authSpinnerState } from '../../stores/auth/authSpinnerState'
import { NavigationLinks } from '../../stories/Navbar_v2/constants'
import { logRocketIdentify } from '../analytics/logRocketIdentify'
import { _getUserProfile } from './getUserProfile'
import { postUserToken } from './postUserToken'
import { useCreateUserData } from './useCreateUserData'

export const useProviderRedirect = () => {
    const setAuthSpinner = useSetRecoilState(authSpinnerState)
    const createUserData = useCreateUserData()

    return {
        providerRedirect: async (): Promise<void> => {
            try {
                const providerResult = await firebaseClient.auth().getRedirectResult()

                if (providerResult && providerResult.user) {
                    setAuthSpinner(true)

                    if (providerResult.credential) {
                        var user = providerResult.user

                        const token = await user.getIdToken(true)

                        await postUserToken(token, setAuthSpinner)
                        await createUserData.create(user)

                        logRocketIdentify(user)

                        const userRef: firebaseClient.firestore.DocumentData = firebaseClient
                            .firestore()
                            .collection('users')
                            .doc(user.uid)
                        const userDoc: firebaseClient.firestore.DocumentData = await userRef.get()

                        const userData = userDoc.data()

                        setAuthSpinner(false)

                        // check if it is the user's first time signing on
                        if (providerResult.additionalUserInfo.isNewUser) {
                            router.push('/set-details')
                        } else {
                            if (userData?.business) {
                                router.push(NavigationLinks.TEAM_DASHBOARD)
                            } else {
                                router.push(NavigationLinks.DASHBOARD)
                            }
                        }
                    }
                }
            } catch (err) {
                console.log(err.message)
                setAuthSpinner(false)
            }
        },
    }
}
