import { useSetRecoilState } from 'recoil'
import { firebaseClient } from '../../firebaseClient'
import { userProfileState } from '../../stores/auth/userProfileState'
import { userState } from '../../stores/auth/userState'
import { getUser } from './firebase/getUser'

export const useCreateUserData = () => {
    const setUserProfile = useSetRecoilState(userProfileState)
    const updateUser = useSetRecoilState(userState)

    return {
        create: async (user: firebaseClient.User): Promise<void> => {
            const userRef = await getUser(user.uid)
            console.log(user)

            let userDoc:
                | firebaseClient.firestore.DocumentData
                | {
                      firstname: string
                      lastname: string
                      email: string
                      onboardingStatus: boolean
                      role: string
                  }

            // create a user if the user ref is non existant
            if (!userRef) {
                const nameArray: string[] = user.displayName.split(/\s+/)
                await fetch('/api/auth/createUserProvider', {
                    method: 'POST',
                    body: JSON.stringify({
                        uid: user.uid,
                        email: user.email,
                        firstName: nameArray[0],
                        lastName: nameArray[1],
                        username: '',
                        business: '',
                    }),
                })

                userDoc = {
                    firstname: nameArray[0],
                    lastname: nameArray[1],
                    email: user.email,
                    onboardingStatus: false,
                    role: 'free',
                    username: '',
                    company: '',
                    business: '',
                }

                setUserProfile(userDoc)
            } else {
                setUserProfile(userRef)
            }

            updateUser(user)
        },
    }
}
