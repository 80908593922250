import { atom } from 'recoil'
import { firebaseClient } from '../../firebaseClient'
import UserProfile from '../../types/UserProfile'

export const userProfileState = atom<
    UserProfile | undefined | firebaseClient.firestore.DocumentData
>({
    key: 'userProfile',
    default: {},
})
