import React, { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import ButtonSpinner from '../../components/ButtonSpinner'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    variant?: 'primary' | 'secondary' | 'orange' | 'invert'
    size?: 'x-small' | 'small' | 'medium' | 'large'
    type?: 'button' | 'submit' | 'reset'
    width?: string
    loading?: boolean
    loadingText?: string
    disabled?: boolean
    borderRadius?: string
}

const ButtonStyle = styled.button<{
    variant: string
    size: string
    width: string
    borderRadius: string
}>`
    cursor: pointer;
    background-color: ${(props) =>
        props.variant === 'primary'
            ? props.theme.colors.primary
            : props.variant === 'secondary'
            ? props.theme.colors.secondary
            : props.variant === 'orange'
            ? props.theme.colors.orange
            : '#FFFFFF'};
    color: ${(props) => (props.variant === 'invert' ? props.theme.text?.secondary : '#FFFFFF')};
    width: ${(props) => props.width || 'auto'};
    border-radius: ${(props) => props.borderRadius || '0.5rem'};
    outline: none !important;
    font-weight: bold;
    text-align: center;
    font-size: ${(props) =>
        props.size === 'x-small'
            ? '12px'
            : props.size === 'small'
            ? '14px'
            : props.size === 'medium'
            ? '15px'
            : '18px'};
    padding: ${(props) =>
        props.size === 'x-small'
            ? '10px 6px 6px 6px'
            : props.size === 'small'
            ? '12px 8px 8px 8px'
            : props.size === 'medium'
            ? '14px 12px 12px 12px'
            : '16px 14px 14px 14px'};
    border: ${(props) =>
        props.variant === 'invert' ? `1px ${props.theme.lineColour} solid` : 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    .button-loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-text {
        margin-left: 0.5rem;
    }

    &:disabled {
        filter: grayscale(100%);
        opacity: 0.5;
    }
`

export const Button = ({
    children,
    variant = 'primary',
    size = 'medium',
    loading = false,
    loadingText,
    width,
    disabled,
    borderRadius,
    ...props
}: Props) => {
    return (
        <ButtonStyle
            disabled={disabled}
            variant={variant}
            size={size}
            width={width}
            borderRadius={borderRadius}
            {...props}
        >
            {loading ? (
                <div className="button-loading">
                    <ButtonSpinner height="14px" width="16px" />
                    <span className="loading-text">{loadingText}</span>
                </div>
            ) : (
                children
            )}
        </ButtonStyle>
    )
}
