import '../styles/globals.css'
import 'simplebar/dist/simplebar.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import '../styles/v2/icon-style.css'
import '../styles/v2/index.css'

//import { AppProps, AppContext } from "next/app";
import React, { useEffect, useState } from 'react'
import { RecoilRoot } from 'recoil'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from '../styles/theme.config'
import { init } from '../utils/sentry'
import LogRocket from 'logrocket'
import { AuthProvider } from '../stores/auth'
import { useRouter } from 'next/router'
import BackdropLoader from '../components/Common/Loader'
import { hotjar } from 'react-hotjar'
import ConfirmContextProvider from '../components/Common/CustomConfirm/ConfirmContextProvider'
import { CustomConfirm } from '../components/Common/CustomConfirm/ConfirmModal'

init()

function App({ Component, pageProps, router, query }) {
    const routerPath = useRouter()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        hotjar.initialize(3009534, 6)
    }, [])

    useEffect(() => {
        const handleStart = (url) => {
            url !== routerPath.pathname ? setLoading(true) : setLoading(false)
        }
        const handleComplete = (url) => setLoading(false)
        routerPath.events.on('routeChangeStart', handleStart)
        routerPath.events.on('routeChangeComplete', handleComplete)
        routerPath.events.on('routeChangeError', handleComplete)
    }, [routerPath])

    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: { refetchOnWindowFocus: false },
                },
            })
    )
    const Layout = Component.Layout ? Component.Layout : React.Fragment

    if (
        router.pathname.startsWith('/live') ||
        router.pathname.startsWith('/v1/') ||
        router.pathname.startsWith('/auth') ||
        router.pathname.startsWith('/preview') ||
        router.pathname.startsWith('/template/preview')
    ) {
        return (
            <>
                {!loading ? (
                    <RecoilRoot>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <ConfirmContextProvider>
                                    <Component {...pageProps} />
                                    <CustomConfirm />
                                </ConfirmContextProvider>
                            </Hydrate>
                        </QueryClientProvider>
                    </RecoilRoot>
                ) : (
                    <BackdropLoader isLoading={loading} />
                )}
            </>
        )
    }

    if (
        Object.keys(router.query).length > 0 &&
        router.query.username &&
        router.query.username !== 'undefined'
    ) {
        LogRocket.init('ja1dlu/deployable')
        return (
            <>
                {!loading ? (
                    <RecoilRoot>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <ConfirmContextProvider>
                                    <Component {...pageProps} />
                                    <CustomConfirm />
                                </ConfirmContextProvider>
                            </Hydrate>
                        </QueryClientProvider>
                    </RecoilRoot>
                ) : (
                    <BackdropLoader isLoading={loading} />
                )}
            </>
        )
    }

    return (
        <>
            {!loading ? (
                <RecoilRoot>
                    <QueryClientProvider client={queryClient}>
                        <Hydrate state={pageProps.dehydratedState}>
                            <AuthProvider>
                                <ThemeProvider theme={theme}>
                                    <ConfirmContextProvider>
                                        <GlobalStyle />
                                        <Layout>
                                            <Component {...pageProps} />
                                            <CustomConfirm />
                                        </Layout>
                                    </ConfirmContextProvider>
                                </ThemeProvider>
                            </AuthProvider>
                        </Hydrate>
                    </QueryClientProvider>
                </RecoilRoot>
            ) : (
                <BackdropLoader isLoading={loading} />
            )}
        </>
    )
}

export default App
