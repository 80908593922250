import React from 'react'
import { motion } from 'framer-motion'

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
}

const slideUp = {
    open: { opacity: 1, y: 0, display: 'fixed' },
    closed: { opacity: 0, y: '-10%', display: 'none' },
}

const Modal = ({
    children,
    classes,
    closeEnabled = true,
    modalOpen = false,
    toggleModal,
}: {
    children: React.ReactNode
    classes?: string
    closeEnabled?: boolean
    modalOpen: boolean
    toggleModal: (value: boolean) => void
}) => {
    if (modalOpen) {
        return (
            <>
                <motion.div
                    animate={modalOpen ? 'open' : 'closed'}
                    variants={variants}
                    transition={{ duration: 0.5 }}
                    className="fixed w-full h-full z-20 bg-black bg-opacity-75 inset-0"
                />
                <motion.div
                    animate={modalOpen ? 'open' : 'closed'}
                    variants={slideUp}
                    transition={{ duration: 0.5 }}
                >
                    <div
                        className={`fixed z-20 left-50% top-50% bg-white transform -translate-x-1/2 -translate-y-1/2 flex flex-col z-30 shadow-sm rounded-md max-h-90vh max-w-90vw overflow-y-none ${classes}`}
                    >
                        {closeEnabled && (
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="self-end cursor focus:outline-none focus:ring-2"
                                onClick={(event: React.MouseEvent<HTMLElement>) =>
                                    toggleModal(false)
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="30px"
                                    width="30px"
                                    viewBox="0 0 511.76 511.76"
                                >
                                    <path d="M436.896 74.869c-99.84-99.819-262.208-99.819-362.048 0-99.797 99.819-99.797 262.229 0 362.048 49.92 49.899 115.477 74.837 181.035 74.837s131.093-24.939 181.013-74.837c99.819-99.818 99.819-262.229 0-362.048zm-75.435 256.448c8.341 8.341 8.341 21.824 0 30.165a21.275 21.275 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251l-75.413-75.435-75.392 75.413a21.348 21.348 0 01-15.083 6.251 21.277 21.277 0 01-15.083-6.251c-8.341-8.341-8.341-21.845 0-30.165l75.392-75.413-75.413-75.413c-8.341-8.341-8.341-21.845 0-30.165 8.32-8.341 21.824-8.341 30.165 0l75.413 75.413 75.413-75.413c8.341-8.341 21.824-8.341 30.165 0 8.341 8.32 8.341 21.824 0 30.165l-75.413 75.413 75.415 75.435z" />
                                </svg>
                            </motion.button>
                        )}

                        {children}
                    </div>
                </motion.div>
            </>
        )
    }
    return null
}

export default Modal
