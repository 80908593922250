import React from 'react'
import styled, { keyframes } from 'styled-components'
import { LoadingRocket } from '../SVG'

interface Props {
    isLoading: boolean
}

export default function BackdropLoader({ isLoading }: Props) {
    return (
        <div>
            {isLoading && (
                <Wrapper>
                    <LoadingRocket />
                    <span>Loading...</span>
                </Wrapper>
            )}
        </div>
    )
}

const loadDots = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`

const rocketAnimation = keyframes`
  from {
    transform: scale(.9);
    transform: translate3d(0, -4px, 0);
  }
  50% {
    transform: scale(1);
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: scale(.9);
    transform: translate3d(0, -4px, 0);
  }
`

const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > span {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #717f95;
        clip-path: inset(0 1ch 0 0);
        animation: ${loadDots} 1s steps(3) infinite;
    }

    & > svg {
        margin-bottom: 35px;
        animation: ${rocketAnimation} 1s infinite;
    }
`
