import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
}))

interface Props {
    visible: boolean
    toggleVisible?(value: boolean): void
    children: React.ReactNode
    title: string
}

export const CustomModal = ({ children, visible, toggleVisible, title }: Props) => {
    const classes = useStyles()

    const onClose = () => {
        if (toggleVisible) {
            toggleVisible(!visible)
        } else {
            return () => {}
        }
    }

    return (
        <Modal
            aria-labelledby={title}
            aria-describedby={title}
            className={classes.modal}
            open={visible}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={visible}>{children as unknown as JSX.Element}</Fade>
        </Modal>
    )
}
