import firebaseClient from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

if (typeof window !== "undefined" && !firebaseClient.apps.length) {
  const CLIENT_CONFIG = {
    apiKey: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF == "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_API_KEY : process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_AUTH_DOMAIN :  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ,
    databaseURL: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_DB_URL : process.env.NEXT_PUBLIC_FIREBASE_DB_URL,
    projectId: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_PROJECT_ID : process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_STORAGE_BUCKET : process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_MESSAGING_SENDER_ID : process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId:  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_APP_ID : process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ==  "dev" ? process.env.NEXT_PUBLIC_DEV_FIREBASE_MEASUREMENT_ID : process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  };

  firebaseClient.initializeApp(CLIENT_CONFIG);
  firebaseClient
    .auth()
    .setPersistence(firebaseClient.auth.Auth.Persistence.LOCAL);
  (window as any).firebase = firebaseClient;
}

export { firebaseClient };
